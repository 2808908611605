import { useEffect } from "react";
import { useState } from "react";
import { browserName } from "react-device-detect";

export interface ICheckBrowserSupport {
  isChecked: boolean;
  isSupport: boolean;
}

export function useSupportedBrowser() {
  const [isBrowserChecked, setIsChecked] = useState<boolean>(false);
  const [isSupportedBrowser, setIsSupportedBrowser] = useState<boolean>();

  useEffect(() => {
    // if (browserName === "WebKit" || browserName === "Line" || browserName === "Chrome WebView") {
    //   setIsSupportedBrowser(false);
    // } else {
    //   setIsSupportedBrowser(true);
    // }

    setIsSupportedBrowser(true);
    setIsChecked(true);
  }, []);

  return { isBrowserChecked, isSupportedBrowser };
}
